.form.form-login {

	.field {
		width: 100%;
	}

	.form-btn {
		width: 100%;
	}
	.error {
		font-size: 1.0625rem;
		padding: 1rem;
	}

	.field.form-login-otp {
		&.valid, &.invalid {
			&:after {
				right: -1.25rem;
				top: 1rem;
			}
		}
	
		.invalid-message{
			display: none;
		}
	
		&.invalid{
			.invalid-message{
				display: block;
				margin-top: 1rem;
			}
		}
	}

	.id-container {
		padding-bottom: 1rem;

		.id-option{
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			input[type=radio] {
				margin-right: 1rem;
				margin-top: 0;
				accent-color: var(--color-primary);
				width:1rem;
				height: 1rem;
			}
		}
	}

	.field-login-mobileNumber {

		.mobile-tooltip {
			font-size: 0.875rem;

			a {
				@media screen and (min-width: 992px) {
					text-decoration: none;
				}
			}
		}

		&.invalid, &.valid {
			position: relative;
		}

		&.invalid{

			.invalid-message {
				display: block;
				font-size: 0.875rem;
				margin-top: 0.5rem;
			}
		}

		&.valid {
			position: relative;
		}

		.field-login-dob {
		
			&.invalid, &.valid {
				position: relative;

				.dob-fields{
					position: relative;
					width: fit-content;
				}
			}

			&.invalid{
				.invalid-message{
					display: block;
					font-size: 0.875rem;
					margin-top: 0.5rem;
				}
			}

			&.valid{
				position: relative;
			}
		}

		.field-login-otp {
			position: relative;
			
			.invalid-message{
				display: none;
			}

			&.invalid {
				.invalid-message {
					display: block;
					margin-top: 1rem;
				}
			}

			@media screen and (min-width: 992px) {
				max-width: 300px;
			}
		}

		.resend-link {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.label-container {
		display: flex; 
		gap: 1rem;
		align-items: flex-start;
		position: relative;
		label {
			display: block;
			padding-bottom: 0.5rem;
		}
		
		.info-icon:hover ~ .tooltip, .tooltip:hover {
			transform: scale(1);
			opacity: 1;
			transition-delay: 0s;
		}

		.tooltip {
			transition: all .25s ease-in-out;
			transform: scale(0);
			transition-delay: 0.25s;
			transform-origin: top center;
			opacity: 0;
			position: absolute;
			background: #FFFFFF;
			border-radius: 4px;
			top: 1.4rem;
			padding: 0.5rem;
			box-shadow: 1px 1px 3px #707070;
			font-size: 0.9375rem;
			color: var(--color-text);
			z-index: 1;
		}
	}

	.field-login-mobileNumber, .field-login-emailAddress {
		padding-bottom: 1rem;

		input {
			width: 100% !important;
			height: 2.5rem;
			padding: 0.5rem;
			font-size: 1.0625rem;
			box-sizing: border-box;
			border-radius: 4px;
			border: 1px solid #707070;
		}
		
	}

	.field.field-login-dob {
		display: inline-block;
		width: auto;
		label {
			display: block;
			padding-bottom: 0.5rem;
		}
		padding-bottom: 1rem;

		fieldset { 
			border: 0;
			padding: 0;
			label {
				display: none;
			}

			input {
				width: 4rem;
				text-align: center;
				height: 2.5rem;
				border-radius: 4px;
				border: 1px solid #707070;
				font-size: 1.0625rem;
				margin-right: 0.5rem;
				padding: 0 0.5rem;
			}
		}

	}

	.mobileNumber {
		padding:1rem 0 ;
	}

	.field.field-login-mobileNumber::after {
		top: 2.25rem;
		right: calc(-1.5rem);
	}

	.field.field-login-dob::after {
		top: 2.5rem;
		right: -1.25rem;
	}

	.field-login-otp {

		.otp-field-container {
			display: flex;
			gap: 1rem;

			.otp-field {
				flex: 1;
				width: 0;
				height: 2.6rem;
				text-align: center;
				border-radius: 4px;
				border: 1px solid #707070;
				font-size: 1.0625rem;
			}

		}

		.invalid-message { 
			display: block;
			margin-top: 1rem;
		}
	
		.error-message {
			display: none;
		}

	}

	.error {
		background-color: var(--color-error-light-red);
		color: #000000;
		a {
			color: #000000;
		}
	}

	.resend-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.login-btn-container {
		display: flex;
		flex-direction: column;

		@media screen and (min-width: 992px) {
			display: flex;
			gap: 1rem;
			flex-direction: row;
			& > button {
				width: 49%;
			}
		}
	}

	.spinner-overlay {	
		z-index: 1;
	}
}

